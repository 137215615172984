import axios, { AxiosRequestConfig, AxiosError } from 'axios'
import aspida from '@aspida/axios'
import { useErrorHandler } from 'react-error-boundary'
import * as Sentry from '@sentry/nextjs'
import { UseFormSetError, FieldValues } from 'react-hook-form'
import { ToastContent } from 'react-toastify'
import { BACKEND_API_URL } from 'settings'
import api from 'aspida/$api'

const axiosConfig: AxiosRequestConfig = {
  baseURL: BACKEND_API_URL,
  withCredentials: true,
  headers: { accept: 'application/json' },
}

export const php = api(aspida(axios, axiosConfig))

type ErrorBody = {
  message: string
  errors?: {
    [k: string]: string[]
  }
}

export type LogiAxiosError = AxiosError<ErrorBody>

type UseCommonErrorHandlerOptions<T extends FieldValues> = {
  /**
   * useFormのsetError 422の時にバリデーションエラーをセットするため
   */
  setError?: UseFormSetError<T>
}
type UseCommonErrorHandlerReturn = {
  /**
   * 既知のエラー処理を行う。
   *
   * @returns null (エラーを処理済みの場合)
   * @returns LogiAxiosError (エラーが未処理の場合。このerrorを使って自分で処理してください)
   */
  handleCommonError: (error: unknown) => null | LogiAxiosError
  handleError: (error: unknown) => void
}

/**
 * POST,PUT,DELETE時の既知のエラー処理を行う関数を返すhooks
 */
export const useCommonErrorHandler: <T extends FieldValues>(
  options?: UseCommonErrorHandlerOptions<T>
) => UseCommonErrorHandlerReturn = (options = {}) => {
  const { setError } = options
  const handleError = useErrorHandler()

  const handleCommonError = (error: unknown) => {
    const err = error as LogiAxiosError
    switch (err.response?.status) {
      case 401:
      case 403:
      case 419: {
        handleError(error)

        return null
      }
      case 422: {
        if (!setError) return err

        /*
         * apiのエラーメッセージをそれぞれのフィールドに表示する
         */
        for (const [name, messages] of Object.entries(
          err.response?.data.errors ?? {}
        )) {
          for (const message of messages) {
            setError(name as Parameters<typeof setError>[0], {
              type: 'manual',
              message,
            })
          }
        }

        return null
      }
      default: {
        Sentry.captureException(err)

        return err
      }
    }
  }

  return { handleCommonError, handleError }
}

export class ManualError extends Error {
  response: { status: number }
  constructor(status: number, message?: string) {
    super(message)
    this.response = { status }
  }
}

/**
 * toast.promiseのerrorに渡す関数を返す関数。422の場合はそれっぽいメッセージを吐き。それ以外はError['message']を吐く。
 *
 * functionかstringで引数を指定した場合422以外ではその文字列がtoastに表示される
 */
export const renderErrorToastMessage =
  (customMesseage?: string | ((err: LogiAxiosError) => string)): ToastContent =>
  ({ data }) => {
    const err = data as LogiAxiosError
    switch (err.response?.status) {
      case 422: {
        return '入力項目を正しい形式に修正してください'
      }
      default: {
        if (!customMesseage) return err.message
        if (typeof customMesseage === 'string') return customMesseage

        return customMesseage(err)
      }
    }
  }
