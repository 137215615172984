/* eslint-disable */
// prettier-ignore
import type { AspidaClient } from 'aspida'
// prettier-ignore
import { dataToURLString } from 'aspida'
// prettier-ignore
import type { Methods as Methods0 } from './advance_shipments'
// prettier-ignore
import type { Methods as Methods1 } from './auth/csrf-cookie'
// prettier-ignore
import type { Methods as Methods2 } from './auth/me'
// prettier-ignore
import type { Methods as Methods3 } from './auth/signin'
// prettier-ignore
import type { Methods as Methods4 } from './auth/signout'
// prettier-ignore
import type { Methods as Methods5 } from './food_labels'
// prettier-ignore
import type { Methods as Methods6 } from './food_labels/_id@number'
// prettier-ignore
import type { Methods as Methods7 } from './invoices'
// prettier-ignore
import type { Methods as Methods8 } from './invoices/add_printout'
// prettier-ignore
import type { Methods as Methods9 } from './invoices/printout'
// prettier-ignore
import type { Methods as Methods10 } from './invoices/re_printout'
// prettier-ignore
import type { Methods as Methods11 } from './logiura'
// prettier-ignore
import type { Methods as Methods12 } from './logiura/register_logo'
// prettier-ignore
import type { Methods as Methods13 } from './manufacturers'
// prettier-ignore
import type { Methods as Methods14 } from './manufacturers/_id@number'
// prettier-ignore
import type { Methods as Methods15 } from './nextengine/redirect'
// prettier-ignore
import type { Methods as Methods16 } from './nextengine/token/expired'
// prettier-ignore
import type { Methods as Methods17 } from './nextengine/token/register'
// prettier-ignore
import type { Methods as Methods18 } from './picking_lists'
// prettier-ignore
import type { Methods as Methods19 } from './picking_lists/future'
// prettier-ignore
import type { Methods as Methods20 } from './printer_users'
// prettier-ignore
import type { Methods as Methods21 } from './product_components'
// prettier-ignore
import type { Methods as Methods22 } from './product_components/_id@number'
// prettier-ignore
import type { Methods as Methods23 } from './product_images'
// prettier-ignore
import type { Methods as Methods24 } from './product_types'
// prettier-ignore
import type { Methods as Methods25 } from './product_variant_images'
// prettier-ignore
import type { Methods as Methods26 } from './products'
// prettier-ignore
import type { Methods as Methods27 } from './products/_id@number'
// prettier-ignore
import type { Methods as Methods28 } from './roles'
// prettier-ignore
import type { Methods as Methods29 } from './shipment_products/_id@number'
// prettier-ignore
import type { Methods as Methods30 } from './shipment_start'
// prettier-ignore
import type { Methods as Methods31 } from './shipment_statuses'
// prettier-ignore
import type { Methods as Methods32 } from './shipment_work/abort'
// prettier-ignore
import type { Methods as Methods33 } from './shipment_work/complete'
// prettier-ignore
import type { Methods as Methods34 } from './shipment_work/confirm'
// prettier-ignore
import type { Methods as Methods35 } from './shipment_work/skip'
// prettier-ignore
import type { Methods as Methods36 } from './shipment_work/start'
// prettier-ignore
import type { Methods as Methods37 } from './shipment_works'
// prettier-ignore
import type { Methods as Methods38 } from './shipments'
// prettier-ignore
import type { Methods as Methods39 } from './shipments/_id@number'
// prettier-ignore
import type { Methods as Methods40 } from './sort_codes'
// prettier-ignore
import type { Methods as Methods41 } from './temperature_zones'
// prettier-ignore
import type { Methods as Methods42 } from './time_zones'
// prettier-ignore
import type { Methods as Methods43 } from './users'
// prettier-ignore
import type { Methods as Methods44 } from './users/_id@number'
// prettier-ignore
import type { Methods as Methods45 } from './users/password/verify'
// prettier-ignore
import type { Methods as Methods46 } from './vendors'
// prettier-ignore
import type { Methods as Methods47 } from './vendors/_id@number'

// prettier-ignore
const api = <T>({ baseURL, fetch }: AspidaClient<T>) => {
  const prefix = (baseURL === undefined ? '' : baseURL).replace(/\/$/, '')
  const PATH0 = '/advance_shipments'
  const PATH1 = '/auth/csrf-cookie'
  const PATH2 = '/auth/me'
  const PATH3 = '/auth/signin'
  const PATH4 = '/auth/signout'
  const PATH5 = '/food_labels'
  const PATH6 = '/invoices'
  const PATH7 = '/invoices/add_printout'
  const PATH8 = '/invoices/printout'
  const PATH9 = '/invoices/re_printout'
  const PATH10 = '/logiura'
  const PATH11 = '/logiura/register_logo'
  const PATH12 = '/manufacturers'
  const PATH13 = '/nextengine/redirect'
  const PATH14 = '/nextengine/token/expired'
  const PATH15 = '/nextengine/token/register'
  const PATH16 = '/picking_lists'
  const PATH17 = '/picking_lists/future'
  const PATH18 = '/printer_users'
  const PATH19 = '/product_components'
  const PATH20 = '/product_images'
  const PATH21 = '/product_types'
  const PATH22 = '/product_variant_images'
  const PATH23 = '/products'
  const PATH24 = '/roles'
  const PATH25 = '/shipment_products'
  const PATH26 = '/shipment_start'
  const PATH27 = '/shipment_statuses'
  const PATH28 = '/shipment_work/abort'
  const PATH29 = '/shipment_work/complete'
  const PATH30 = '/shipment_work/confirm'
  const PATH31 = '/shipment_work/skip'
  const PATH32 = '/shipment_work/start'
  const PATH33 = '/shipment_works'
  const PATH34 = '/shipments'
  const PATH35 = '/sort_codes'
  const PATH36 = '/temperature_zones'
  const PATH37 = '/time_zones'
  const PATH38 = '/users'
  const PATH39 = '/users/password/verify'
  const PATH40 = '/vendors'
  const GET = 'GET'
  const POST = 'POST'
  const PUT = 'PUT'
  const DELETE = 'DELETE'

  return {
    advance_shipments: {
      get: (option: { query: Methods0['get']['query'], config?: T }) =>
        fetch<Methods0['get']['resBody']>(prefix, PATH0, GET, option).json(),
      $get: (option: { query: Methods0['get']['query'], config?: T }) =>
        fetch<Methods0['get']['resBody']>(prefix, PATH0, GET, option).json().then(r => r.body),
      post: (option: { body: Methods0['post']['reqBody'], config?: T }) =>
        fetch<Methods0['post']['resBody']>(prefix, PATH0, POST, option).json(),
      $post: (option: { body: Methods0['post']['reqBody'], config?: T }) =>
        fetch<Methods0['post']['resBody']>(prefix, PATH0, POST, option).json().then(r => r.body),
      $path: (option?: { method?: 'get'; query: Methods0['get']['query'] }) =>
        `${prefix}${PATH0}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    auth: {
      csrf_cookie: {
        get: (option?: { config?: T }) =>
          fetch<Methods1['get']['resBody']>(prefix, PATH1, GET, option).json(),
        $get: (option?: { config?: T }) =>
          fetch<Methods1['get']['resBody']>(prefix, PATH1, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH1}`
      },
      me: {
        get: (option?: { config?: T }) =>
          fetch<Methods2['get']['resBody']>(prefix, PATH2, GET, option).json(),
        $get: (option?: { config?: T }) =>
          fetch<Methods2['get']['resBody']>(prefix, PATH2, GET, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH2}`
      },
      signin: {
        post: (option: { body: Methods3['post']['reqBody'], config?: T }) =>
          fetch<Methods3['post']['resBody']>(prefix, PATH3, POST, option).json(),
        $post: (option: { body: Methods3['post']['reqBody'], config?: T }) =>
          fetch<Methods3['post']['resBody']>(prefix, PATH3, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH3}`
      },
      signout: {
        post: (option?: { body?: Methods4['post']['reqBody'], config?: T }) =>
          fetch<Methods4['post']['resBody']>(prefix, PATH4, POST, option).json(),
        $post: (option?: { body?: Methods4['post']['reqBody'], config?: T }) =>
          fetch<Methods4['post']['resBody']>(prefix, PATH4, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH4}`
      }
    },
    food_labels: {
      _id: (val1: number) => {
        const prefix1 = `${PATH5}/${val1}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods6['get']['resBody']>(prefix, prefix1, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods6['get']['resBody']>(prefix, prefix1, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      post: (option: { body: Methods5['post']['reqBody'], config?: T }) =>
        fetch<Methods5['post']['resBody']>(prefix, PATH5, POST, option).json(),
      $post: (option: { body: Methods5['post']['reqBody'], config?: T }) =>
        fetch<Methods5['post']['resBody']>(prefix, PATH5, POST, option).json().then(r => r.body),
      put: (option: { body: Methods5['put']['reqBody'], config?: T }) =>
        fetch<Methods5['put']['resBody']>(prefix, PATH5, PUT, option).json(),
      $put: (option: { body: Methods5['put']['reqBody'], config?: T }) =>
        fetch<Methods5['put']['resBody']>(prefix, PATH5, PUT, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH5}`
    },
    invoices: {
      add_printout: {
        post: (option: { body: Methods8['post']['reqBody'], config?: T }) =>
          fetch<Methods8['post']['resBody']>(prefix, PATH7, POST, option).json(),
        $post: (option: { body: Methods8['post']['reqBody'], config?: T }) =>
          fetch<Methods8['post']['resBody']>(prefix, PATH7, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH7}`
      },
      printout: {
        post: (option: { body: Methods9['post']['reqBody'], config?: T }) =>
          fetch<Methods9['post']['resBody']>(prefix, PATH8, POST, option).json(),
        $post: (option: { body: Methods9['post']['reqBody'], config?: T }) =>
          fetch<Methods9['post']['resBody']>(prefix, PATH8, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH8}`
      },
      re_printout: {
        post: (option: { body: Methods10['post']['reqBody'], config?: T }) =>
          fetch<Methods10['post']['resBody']>(prefix, PATH9, POST, option).json(),
        $post: (option: { body: Methods10['post']['reqBody'], config?: T }) =>
          fetch<Methods10['post']['resBody']>(prefix, PATH9, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH9}`
      },
      get: (option: { query: Methods7['get']['query'], config?: T }) =>
        fetch<Methods7['get']['resBody']>(prefix, PATH6, GET, option).json(),
      $get: (option: { query: Methods7['get']['query'], config?: T }) =>
        fetch<Methods7['get']['resBody']>(prefix, PATH6, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get'; query: Methods7['get']['query'] }) =>
        `${prefix}${PATH6}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    logiura: {
      register_logo: {
        post: (option: { body: Methods12['post']['reqBody'], config?: T }) =>
          fetch<Methods12['post']['resBody']>(prefix, PATH11, POST, option, 'FormData').json(),
        $post: (option: { body: Methods12['post']['reqBody'], config?: T }) =>
          fetch<Methods12['post']['resBody']>(prefix, PATH11, POST, option, 'FormData').json().then(r => r.body),
        $path: () => `${prefix}${PATH11}`
      },
      get: (option?: { config?: T }) =>
        fetch<Methods11['get']['resBody']>(prefix, PATH10, GET, option).json(),
      $get: (option?: { config?: T }) =>
        fetch<Methods11['get']['resBody']>(prefix, PATH10, GET, option).json().then(r => r.body),
      put: (option: { body: Methods11['put']['reqBody'], config?: T }) =>
        fetch<Methods11['put']['resBody']>(prefix, PATH10, PUT, option).json(),
      $put: (option: { body: Methods11['put']['reqBody'], config?: T }) =>
        fetch<Methods11['put']['resBody']>(prefix, PATH10, PUT, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH10}`
    },
    manufacturers: {
      _id: (val1: number) => {
        const prefix1 = `${PATH12}/${val1}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods14['get']['resBody']>(prefix, prefix1, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods14['get']['resBody']>(prefix, prefix1, GET, option).json().then(r => r.body),
          put: (option: { body: Methods14['put']['reqBody'], config?: T }) =>
            fetch<Methods14['put']['resBody']>(prefix, prefix1, PUT, option).json(),
          $put: (option: { body: Methods14['put']['reqBody'], config?: T }) =>
            fetch<Methods14['put']['resBody']>(prefix, prefix1, PUT, option).json().then(r => r.body),
          delete: (option?: { config?: T }) =>
            fetch<Methods14['delete']['resBody']>(prefix, prefix1, DELETE, option).json(),
          $delete: (option?: { config?: T }) =>
            fetch<Methods14['delete']['resBody']>(prefix, prefix1, DELETE, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      post: (option: { body: Methods13['post']['reqBody'], config?: T }) =>
        fetch<Methods13['post']['resBody']>(prefix, PATH12, POST, option).json(),
      $post: (option: { body: Methods13['post']['reqBody'], config?: T }) =>
        fetch<Methods13['post']['resBody']>(prefix, PATH12, POST, option).json().then(r => r.body),
      get: (option?: { query?: Methods13['get']['query'], config?: T }) =>
        fetch<Methods13['get']['resBody']>(prefix, PATH12, GET, option).json(),
      $get: (option?: { query?: Methods13['get']['query'], config?: T }) =>
        fetch<Methods13['get']['resBody']>(prefix, PATH12, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get'; query: Methods13['get']['query'] }) =>
        `${prefix}${PATH12}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    nextengine: {
      redirect: {
        get: (option?: { query?: Methods15['get']['query'], config?: T }) =>
          fetch<Methods15['get']['resBody']>(prefix, PATH13, GET, option).json(),
        $get: (option?: { query?: Methods15['get']['query'], config?: T }) =>
          fetch<Methods15['get']['resBody']>(prefix, PATH13, GET, option).json().then(r => r.body),
        $path: (option?: { method?: 'get'; query: Methods15['get']['query'] }) =>
          `${prefix}${PATH13}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
      },
      token: {
        expired: {
          get: (option?: { config?: T }) =>
            fetch<Methods16['get']['resBody']>(prefix, PATH14, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods16['get']['resBody']>(prefix, PATH14, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH14}`
        },
        register: {
          post: (option: { body: Methods17['post']['reqBody'], config?: T }) =>
            fetch(prefix, PATH15, POST, option).send(),
          $post: (option: { body: Methods17['post']['reqBody'], config?: T }) =>
            fetch(prefix, PATH15, POST, option).send().then(r => r.body),
          $path: () => `${prefix}${PATH15}`
        }
      }
    },
    picking_lists: {
      future: {
        post: (option: { body: Methods19['post']['reqBody'], config?: T }) =>
          fetch<Methods19['post']['resBody']>(prefix, PATH17, POST, option).json(),
        $post: (option: { body: Methods19['post']['reqBody'], config?: T }) =>
          fetch<Methods19['post']['resBody']>(prefix, PATH17, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH17}`
      },
      get: (option: { query: Methods18['get']['query'], config?: T }) =>
        fetch<Methods18['get']['resBody']>(prefix, PATH16, GET, option).json(),
      $get: (option: { query: Methods18['get']['query'], config?: T }) =>
        fetch<Methods18['get']['resBody']>(prefix, PATH16, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get'; query: Methods18['get']['query'] }) =>
        `${prefix}${PATH16}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    printer_users: {
      get: (option?: { config?: T }) =>
        fetch<Methods20['get']['resBody']>(prefix, PATH18, GET, option).json(),
      $get: (option?: { config?: T }) =>
        fetch<Methods20['get']['resBody']>(prefix, PATH18, GET, option).json().then(r => r.body),
      post: (option: { body: Methods20['post']['reqBody'], config?: T }) =>
        fetch<Methods20['post']['resBody']>(prefix, PATH18, POST, option).json(),
      $post: (option: { body: Methods20['post']['reqBody'], config?: T }) =>
        fetch<Methods20['post']['resBody']>(prefix, PATH18, POST, option).json().then(r => r.body),
      delete: (option: { body: Methods20['delete']['reqBody'], config?: T }) =>
        fetch(prefix, PATH18, DELETE, option).send(),
      $delete: (option: { body: Methods20['delete']['reqBody'], config?: T }) =>
        fetch(prefix, PATH18, DELETE, option).send().then(r => r.body),
      $path: () => `${prefix}${PATH18}`
    },
    product_components: {
      _id: (val1: number) => {
        const prefix1 = `${PATH19}/${val1}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods22['get']['resBody']>(prefix, prefix1, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods22['get']['resBody']>(prefix, prefix1, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      post: (option: { body: Methods21['post']['reqBody'], config?: T }) =>
        fetch<Methods21['post']['resBody']>(prefix, PATH19, POST, option, 'FormData').json(),
      $post: (option: { body: Methods21['post']['reqBody'], config?: T }) =>
        fetch<Methods21['post']['resBody']>(prefix, PATH19, POST, option, 'FormData').json().then(r => r.body),
      $path: () => `${prefix}${PATH19}`
    },
    product_images: {
      post: (option: { body: Methods23['post']['reqBody'], config?: T }) =>
        fetch<Methods23['post']['resBody']>(prefix, PATH20, POST, option, 'FormData').json(),
      $post: (option: { body: Methods23['post']['reqBody'], config?: T }) =>
        fetch<Methods23['post']['resBody']>(prefix, PATH20, POST, option, 'FormData').json().then(r => r.body),
      $path: () => `${prefix}${PATH20}`
    },
    product_types: {
      get: (option?: { config?: T }) =>
        fetch<Methods24['get']['resBody']>(prefix, PATH21, GET, option).json(),
      $get: (option?: { config?: T }) =>
        fetch<Methods24['get']['resBody']>(prefix, PATH21, GET, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH21}`
    },
    product_variant_images: {
      post: (option: { body: Methods25['post']['reqBody'], config?: T }) =>
        fetch<Methods25['post']['resBody']>(prefix, PATH22, POST, option, 'FormData').json(),
      $post: (option: { body: Methods25['post']['reqBody'], config?: T }) =>
        fetch<Methods25['post']['resBody']>(prefix, PATH22, POST, option, 'FormData').json().then(r => r.body),
      $path: () => `${prefix}${PATH22}`
    },
    products: {
      _id: (val1: number) => {
        const prefix1 = `${PATH23}/${val1}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods27['get']['resBody']>(prefix, prefix1, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods27['get']['resBody']>(prefix, prefix1, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      get: (option: { query: Methods26['get']['query'], config?: T }) =>
        fetch<Methods26['get']['resBody']>(prefix, PATH23, GET, option).json(),
      $get: (option: { query: Methods26['get']['query'], config?: T }) =>
        fetch<Methods26['get']['resBody']>(prefix, PATH23, GET, option).json().then(r => r.body),
      post: (option?: { config?: T }) =>
        fetch<Methods26['post']['resBody']>(prefix, PATH23, POST, option).json(),
      $post: (option?: { config?: T }) =>
        fetch<Methods26['post']['resBody']>(prefix, PATH23, POST, option).json().then(r => r.body),
      $path: (option?: { method?: 'get'; query: Methods26['get']['query'] }) =>
        `${prefix}${PATH23}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    roles: {
      get: (option?: { config?: T }) =>
        fetch<Methods28['get']['resBody']>(prefix, PATH24, GET, option).json(),
      $get: (option?: { config?: T }) =>
        fetch<Methods28['get']['resBody']>(prefix, PATH24, GET, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH24}`
    },
    shipment_products: {
      _id: (val1: number) => {
        const prefix1 = `${PATH25}/${val1}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods29['get']['resBody']>(prefix, prefix1, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods29['get']['resBody']>(prefix, prefix1, GET, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      }
    },
    shipment_start: {
      post: (option?: { config?: T }) =>
        fetch<Methods30['post']['resBody']>(prefix, PATH26, POST, option).json(),
      $post: (option?: { config?: T }) =>
        fetch<Methods30['post']['resBody']>(prefix, PATH26, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH26}`
    },
    shipment_statuses: {
      get: (option?: { config?: T }) =>
        fetch<Methods31['get']['resBody']>(prefix, PATH27, GET, option).json(),
      $get: (option?: { config?: T }) =>
        fetch<Methods31['get']['resBody']>(prefix, PATH27, GET, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH27}`
    },
    shipment_work: {
      abort: {
        post: (option: { body: Methods32['post']['reqBody'], config?: T }) =>
          fetch<Methods32['post']['resBody']>(prefix, PATH28, POST, option).json(),
        $post: (option: { body: Methods32['post']['reqBody'], config?: T }) =>
          fetch<Methods32['post']['resBody']>(prefix, PATH28, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH28}`
      },
      complete: {
        post: (option: { body: Methods33['post']['reqBody'], config?: T }) =>
          fetch<Methods33['post']['resBody']>(prefix, PATH29, POST, option).json(),
        $post: (option: { body: Methods33['post']['reqBody'], config?: T }) =>
          fetch<Methods33['post']['resBody']>(prefix, PATH29, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH29}`
      },
      confirm: {
        post: (option: { body: Methods34['post']['reqBody'], config?: T }) =>
          fetch<Methods34['post']['resBody']>(prefix, PATH30, POST, option).json(),
        $post: (option: { body: Methods34['post']['reqBody'], config?: T }) =>
          fetch<Methods34['post']['resBody']>(prefix, PATH30, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH30}`
      },
      skip: {
        post: (option: { body: Methods35['post']['reqBody'], config?: T }) =>
          fetch<Methods35['post']['resBody']>(prefix, PATH31, POST, option).json(),
        $post: (option: { body: Methods35['post']['reqBody'], config?: T }) =>
          fetch<Methods35['post']['resBody']>(prefix, PATH31, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH31}`
      },
      start: {
        post: (option: { body: Methods36['post']['reqBody'], config?: T }) =>
          fetch<Methods36['post']['resBody']>(prefix, PATH32, POST, option).json(),
        $post: (option: { body: Methods36['post']['reqBody'], config?: T }) =>
          fetch<Methods36['post']['resBody']>(prefix, PATH32, POST, option).json().then(r => r.body),
        $path: () => `${prefix}${PATH32}`
      }
    },
    shipment_works: {
      get: (option: { query: Methods37['get']['query'], config?: T }) =>
        fetch<Methods37['get']['resBody']>(prefix, PATH33, GET, option).json(),
      $get: (option: { query: Methods37['get']['query'], config?: T }) =>
        fetch<Methods37['get']['resBody']>(prefix, PATH33, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get'; query: Methods37['get']['query'] }) =>
        `${prefix}${PATH33}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    shipments: {
      _id: (val1: number) => {
        const prefix1 = `${PATH34}/${val1}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods39['get']['resBody']>(prefix, prefix1, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods39['get']['resBody']>(prefix, prefix1, GET, option).json().then(r => r.body),
          put: (option: { body: Methods39['put']['reqBody'], config?: T }) =>
            fetch<Methods39['put']['resBody']>(prefix, prefix1, PUT, option).json(),
          $put: (option: { body: Methods39['put']['reqBody'], config?: T }) =>
            fetch<Methods39['put']['resBody']>(prefix, prefix1, PUT, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      get: (option: { query: Methods38['get']['query'], config?: T }) =>
        fetch<Methods38['get']['resBody']>(prefix, PATH34, GET, option).json(),
      $get: (option: { query: Methods38['get']['query'], config?: T }) =>
        fetch<Methods38['get']['resBody']>(prefix, PATH34, GET, option).json().then(r => r.body),
      post: (option: { body: Methods38['post']['reqBody'], config?: T }) =>
        fetch<Methods38['post']['resBody']>(prefix, PATH34, POST, option).json(),
      $post: (option: { body: Methods38['post']['reqBody'], config?: T }) =>
        fetch<Methods38['post']['resBody']>(prefix, PATH34, POST, option).json().then(r => r.body),
      $path: (option?: { method?: 'get'; query: Methods38['get']['query'] }) =>
        `${prefix}${PATH34}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    sort_codes: {
      post: (option: { body: Methods40['post']['reqBody'], config?: T }) =>
        fetch<Methods40['post']['resBody']>(prefix, PATH35, POST, option).json(),
      $post: (option: { body: Methods40['post']['reqBody'], config?: T }) =>
        fetch<Methods40['post']['resBody']>(prefix, PATH35, POST, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH35}`
    },
    temperature_zones: {
      get: (option?: { config?: T }) =>
        fetch<Methods41['get']['resBody']>(prefix, PATH36, GET, option).json(),
      $get: (option?: { config?: T }) =>
        fetch<Methods41['get']['resBody']>(prefix, PATH36, GET, option).json().then(r => r.body),
      $path: () => `${prefix}${PATH36}`
    },
    time_zones: {
      get: (option: { query: Methods42['get']['query'], config?: T }) =>
        fetch<Methods42['get']['resBody']>(prefix, PATH37, GET, option).json(),
      $get: (option: { query: Methods42['get']['query'], config?: T }) =>
        fetch<Methods42['get']['resBody']>(prefix, PATH37, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get'; query: Methods42['get']['query'] }) =>
        `${prefix}${PATH37}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    users: {
      _id: (val1: number) => {
        const prefix1 = `${PATH38}/${val1}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods44['get']['resBody']>(prefix, prefix1, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods44['get']['resBody']>(prefix, prefix1, GET, option).json().then(r => r.body),
          put: (option: { body: Methods44['put']['reqBody'], config?: T }) =>
            fetch<Methods44['put']['resBody']>(prefix, prefix1, PUT, option).json(),
          $put: (option: { body: Methods44['put']['reqBody'], config?: T }) =>
            fetch<Methods44['put']['resBody']>(prefix, prefix1, PUT, option).json().then(r => r.body),
          delete: (option?: { config?: T }) =>
            fetch<Methods44['delete']['resBody']>(prefix, prefix1, DELETE, option).json(),
          $delete: (option?: { config?: T }) =>
            fetch<Methods44['delete']['resBody']>(prefix, prefix1, DELETE, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      password: {
        verify: {
          post: (option: { body: Methods45['post']['reqBody'], config?: T }) =>
            fetch<Methods45['post']['resBody']>(prefix, PATH39, POST, option).json(),
          $post: (option: { body: Methods45['post']['reqBody'], config?: T }) =>
            fetch<Methods45['post']['resBody']>(prefix, PATH39, POST, option).json().then(r => r.body),
          $path: () => `${prefix}${PATH39}`
        }
      },
      post: (option: { body: Methods43['post']['reqBody'], config?: T }) =>
        fetch<Methods43['post']['resBody']>(prefix, PATH38, POST, option).json(),
      $post: (option: { body: Methods43['post']['reqBody'], config?: T }) =>
        fetch<Methods43['post']['resBody']>(prefix, PATH38, POST, option).json().then(r => r.body),
      get: (option: { query: Methods43['get']['query'], config?: T }) =>
        fetch<Methods43['get']['resBody']>(prefix, PATH38, GET, option).json(),
      $get: (option: { query: Methods43['get']['query'], config?: T }) =>
        fetch<Methods43['get']['resBody']>(prefix, PATH38, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get'; query: Methods43['get']['query'] }) =>
        `${prefix}${PATH38}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    },
    vendors: {
      _id: (val1: number) => {
        const prefix1 = `${PATH40}/${val1}`

        return {
          get: (option?: { config?: T }) =>
            fetch<Methods47['get']['resBody']>(prefix, prefix1, GET, option).json(),
          $get: (option?: { config?: T }) =>
            fetch<Methods47['get']['resBody']>(prefix, prefix1, GET, option).json().then(r => r.body),
          put: (option: { body: Methods47['put']['reqBody'], config?: T }) =>
            fetch<Methods47['put']['resBody']>(prefix, prefix1, PUT, option).json(),
          $put: (option: { body: Methods47['put']['reqBody'], config?: T }) =>
            fetch<Methods47['put']['resBody']>(prefix, prefix1, PUT, option).json().then(r => r.body),
          delete: (option?: { config?: T }) =>
            fetch<Methods47['delete']['resBody']>(prefix, prefix1, DELETE, option).json(),
          $delete: (option?: { config?: T }) =>
            fetch<Methods47['delete']['resBody']>(prefix, prefix1, DELETE, option).json().then(r => r.body),
          $path: () => `${prefix}${prefix1}`
        }
      },
      post: (option: { body: Methods46['post']['reqBody'], config?: T }) =>
        fetch<Methods46['post']['resBody']>(prefix, PATH40, POST, option).json(),
      $post: (option: { body: Methods46['post']['reqBody'], config?: T }) =>
        fetch<Methods46['post']['resBody']>(prefix, PATH40, POST, option).json().then(r => r.body),
      get: (option?: { query?: Methods46['get']['query'], config?: T }) =>
        fetch<Methods46['get']['resBody']>(prefix, PATH40, GET, option).json(),
      $get: (option?: { query?: Methods46['get']['query'], config?: T }) =>
        fetch<Methods46['get']['resBody']>(prefix, PATH40, GET, option).json().then(r => r.body),
      $path: (option?: { method?: 'get'; query: Methods46['get']['query'] }) =>
        `${prefix}${PATH40}${option && option.query ? `?${dataToURLString(option.query)}` : ''}`
    }
  }
}

// prettier-ignore
export type ApiInstance = ReturnType<typeof api>
// prettier-ignore
export default api
